import React from 'react';

const SlideInForm = ({ isVisible, onClose, children }) => {
    return (
        <div className={`slide-in-form ${isVisible ? 'slide-in' : 'slide-out'}`}>
            <button onClick={onClose}>X</button>
            {children}
        </div>
    );
};

export default SlideInForm;
