import { BrowserRouter } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import LayoutContainer from './layout/components/LayoutContainer';




function App() {

  return (
    <BrowserRouter>
      <LayoutContainer />
    </BrowserRouter>
  );
}

export default App;
