import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useMutation, gql } from '@apollo/client';

const CREATE_PROVIDER_MUTATION = gql`
  mutation CreateProvider($name: String!) {
    createProvider(
      input: {name: $name, providerObjectType: things, providerType: run_script}
    ) {
      id
      name
    }
  }
`;

const AddModal = ({openAddModal, handleCloseAddModal}) => {
  const [providerName, setProviderName] = useState("");

  const [createProvider, { data }] = useMutation(CREATE_PROVIDER_MUTATION);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await createProvider({ variables: { name: providerName } });
      setProviderName("");
    } catch (error) {
      console.error("Error creating provider: ", error);
    }
  };

  return (
    <div>
      <Dialog open={openAddModal} onClose={handleCloseAddModal} onSubmit={handleSubmit}>
        <DialogTitle>Add Provider</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To create a new provider, please fill out this form.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Provider Name"
            type="text"
            fullWidth
            value={providerName}
            onChange={e => setProviderName(e.target.value)}
          />
          {data && data.createProvider && (
            <DialogContentText>
              Provider created: {data.createProvider.name} with ID {data.createProvider.id}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddModal}>Cancel</Button>
          <Button onClick={handleSubmit} variant="contained">Add Provider</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddModal;
