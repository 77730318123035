import React from 'react';

const AddWorkflowStepButton = ({ onToggle }) => {
    return (
        <button onClick={onToggle} className='addStep'>
          Add Workflow Step
        </button>
    );
};

export default AddWorkflowStepButton;