import React from 'react';
import { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';

const DropDownContainer = styled.div`
    text-align: left;
    border-radius: 5px;
    background: #0F054A
    height: 100px;
`

const DropDownInput = styled.div`
    position:relative;
    background:#FFF;
    padding: 2px;
    max-width:40px;
    display: block;
    width:100%;
    align-items: center;
    text-align: left;
    justify-content: space-between;
    user-select: none;
    color: black;
    height: 36px;
    border-radius: 10px;
    margin-right: 15px;
    overflow:hidden;
    transition: max-width 0.2s ease-out;

    &.active{
        overflow:unset;
        background: #0F054A;
        max-width: 440px;
    }

    @media screen and (min-width: 600px) {
        width:440px;
    }
`
const ButtonsContainer = styled.div`
    display: flex;

    button:first-child SVG{
        position:relative;
        top:4px;
    }
`

const StyledSearchIcon = styled.div`
    margin: 0 0 0 2px;
`
const StyledCloseIcon = styled.div`
    //float: right;
    padding: 10px 7px 0 0;
`
const StyledButton = styled.button`
    border-radius: 10px;
    margin: 5px;
    width: 150px;
    display: flex;
    background:rgba(255, 255, 255, 0.9);
    border:0 none;
    font-size:14px;
    font-family: 'Roboto';
    color:#4B4B4B;
    font-weight:500;
    line-height:26px;
    justify-content:center;
    align-items:center;
    cursor:pointer;
`

const ButtonWrapper = styled.button`
    padding:0;
    background:transparent;
    border:0 none;
    cursor:pointer;
`

const StyledText = styled.p`
    color: #ffffff;
`
const StyledTextInput = styled.input`
    color:#FFF;
    background: transparent;
    border: 0;
    font-size: 17px;
    margin: 8px 0 0 10px;
    position: absolute;
    width: 280px;

    &::placeholder{
        color: #4A4A4A;
    }
`
const DropDownMenu = styled.div`
    top: 42px;
    position: absolute;
    transform: translateY(4px);
    width: 416px;
    border-radius: 5px;
    overflow-y: scroll;
    background-color: #0F054A;
    z-index: 3;
    height: 340px;
    padding:0 12px;

    p{
        font-size:12px;
        color:#9A8FAE;
        margin-bottom:2px;
    }
`

const DropDownItem = styled.div`
    padding: 5px;
    

    &.heading{
        color: #C197C2;
        margin:14px 0 0;
        font-size:12px;
        text-transform:capitalize;
    }

    &:first-child{
        margin-top:0;
    }

    &.link{
        color:#FFF;
        cursor: pointer;
        &:hover {
            background-color: #9fc3f870;
        }    
    }
    
`

const SearchIcon = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ margin: "8px 0 0 10px"}}>
            <circle cx="7.125" cy="7.125" r="6.625" className="magnify-stroke" stroke="#7f7f7f" strokeOpacity="1"/>
            <line x1="11.9884" y1="11.7616" x2="18.5196" y2="18.2929" className="magnify-stroke" stroke="#7f7f7f" strokeOpacity="1" strokeWidth="2"/>
        </svg>
    )
}

const CloseIcon = () => {
    return (
        <StyledCloseIcon>
            <svg fill="#ffffff" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" width="15px" height="15px"><path d="M 4.7070312 3.2929688 L 3.2929688 4.7070312 L 10.585938 12 L 3.2929688 19.292969 L 4.7070312 20.707031 L 12 13.414062 L 19.292969 20.707031 L 20.707031 19.292969 L 13.414062 12 L 20.707031 4.7070312 L 19.292969 3.2929688 L 12 10.585938 L 4.7070312 3.2929688 z"/></svg>
        </StyledCloseIcon>
    )
}

const ArrowIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 14H4L7.5 17.5" stroke="#4B4B4B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M4 10H20L16.5 6.5" stroke="#4B4B4B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

const PeopleIcon = () => {
    return(
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.66667 4.04938C9.66667 5.73351 8.30139 7.09877 6.61728 7.09877C4.93318 7.09877 3.5679 5.73351 3.5679 4.04938C3.5679 2.36525 4.93318 1 6.61728 1C8.30139 1 9.66667 2.36525 9.66667 4.04938Z" stroke="#4B4B4B" strokeWidth="1.5"/>
            <path d="M12.5556 14V14C12.5556 11.1636 10.2562 8.8642 7.41975 8.8642H6.13581C3.29938 8.8642 1 11.1636 1 14V14" stroke="#4B4B4B" strokeWidth="1.5"/>
        </svg>
    )
}

const WorkFlowIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.625 8.25V4.5C8.625 3.67157 9.29657 3 10.125 3H13.875C14.7034 3 15.375 3.67157 15.375 4.5V8.25C15.375 9.07843 14.7034 9.75 13.875 9.75H10.125C9.29657 9.75 8.625 9.07843 8.625 8.25Z" stroke="#4B4B4B" strokeWidth="1.5"/>
            <path d="M6.375 18.75C6.375 17.5074 5.36764 16.5 4.125 16.5C2.88236 16.5 1.875 17.5074 1.875 18.75C1.875 19.9926 2.88236 21 4.125 21C5.36764 21 6.375 19.9926 6.375 18.75Z" stroke="#4B4B4B" strokeWidth="1.5"/>
            <path d="M14.25 18.75C14.25 17.5074 13.2426 16.5 12 16.5C10.7574 16.5 9.75 17.5074 9.75 18.75C9.75 19.9926 10.7574 21 12 21C13.2426 21 14.25 19.9926 14.25 18.75Z" stroke="#4B4B4B" strokeWidth="1.5"/>
            <path d="M22.125 18.75C22.125 17.5074 21.1176 16.5 19.875 16.5C18.6324 16.5 17.625 17.5074 17.625 18.75C17.625 19.9926 18.6324 21 19.875 21C21.1176 21 22.125 19.9926 22.125 18.75Z" stroke="#4B4B4B" strokeWidth="1.5"/>
            <path d="M12 9.75V13.125M12 16.5V13.125M12 13.125H18.375C19.2034 13.125 19.875 13.7966 19.875 14.625V16.5M12 13.125H5.625C4.79657 13.125 4.125 13.7966 4.125 14.625V16.5" stroke="#4B4B4B" strokeWidth="1.5"/>
        </svg>
    )
}

export default function SearchBar({ placeHolder, options, showMenu, handleShowMenu }) {
    const inputRef = useRef();
    const [searchText, setSearchText] = useState('');
    const [searchResults, setSearchResults] = useState({});

    useEffect(() => {
        const delayTimer = setTimeout(handleSearch, 1000);
    
        return () => clearTimeout(delayTimer);
      }, [searchText]);
    

    const handleSearch = async () => {
        try {
        const response = await fetch(`https://dev.rundis.io/graph/search?query=${searchText}`);
        const data = await response.json();

        setSearchResults(data);
        } catch (error) {
            console.error('Error occurred while fetching data:', error);
        }
    };

    return (
        <DropDownContainer>
            <DropDownInput 
                className={showMenu ? "active" : null}
            >
                <ButtonWrapper
                     onClick={handleShowMenu}
                >
                    <SearchIcon onClick={handleSearch} />
                </ButtonWrapper>
                <StyledTextInput 
                    ref={inputRef}
                    placeholder="Search" 
                    type="text"
                    value={searchText}
                    onChange={event => setSearchText(event.target.value)}
                />
                {showMenu && (
                    <DropDownMenu>
                        <StyledText>Search By</StyledText>
                        <ButtonsContainer>
                            <StyledButton
                                
                            >
                                <PeopleIcon />People
                            </StyledButton>
                            <StyledButton><ArrowIcon />Provider</StyledButton>
                            <StyledButton><WorkFlowIcon />Workflows</StyledButton>
                        </ButtonsContainer>
                        {Object.keys(searchResults).map(key => (
                            searchResults[key].count > 0 && (
                            <>
                                <DropDownItem  className='heading'>{key}</DropDownItem >
                                {searchResults[key].results.slice(0, 3).map(result => (
                                    <DropDownItem className='link' key={result.id}>{result.name}</DropDownItem >
                                ))}
                            </>
                            )
                        ))}
                    </DropDownMenu>
                )}
                <ButtonWrapper
                     onClick={handleShowMenu}
                     className='closeIcon'
                >
                   <CloseIcon className={'close-icon'} /> 
                </ButtonWrapper>
            </DropDownInput>
        </DropDownContainer>
    )
}