import React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@mui/material';
import { useQuery, gql } from '@apollo/client';
import { Link } from 'react-router-dom';

const DataTable = (props) => {
    const queryType = props.queryType ? props.queryType : 'providers';
    const hasEvents = props.checkboxes[1].checked ? 'hasEvents: true' : '';
    const hasTriggers = (props.checkboxes[0].checked  && queryType == 'workflows' ) ? 'hasTriggers: true' : '';
    const hasResults = (props.checkboxes[2].checked  && queryType != 'providers') ? 'hasResults: true' : '';
    const hasSteps = (props.checkboxes[3].checked && queryType == 'workflows' ) ? 'hasSteps: true' : '';
    const hasEventsInRange = (queryType == 'providers' && (props.usage[0] != 0 || props.usage[1] != 50)) ? 'hasEventsWithinRange: [' + props.usage[0] + ', ' + props.usage[1] + ']' : '';

    const GET_QUERY = gql`
        query ${queryType}{
            ${queryType}(where:{
                ${hasEvents}
                ${hasTriggers}
                ${hasResults}
                ${hasSteps}
                ${hasEventsInRange}
            }){
            edges{
                node{
                    id
                    name
                    created
                }
            }
            totalCount
            }
    }
    `;

    const { loading, error, data } = useQuery(GET_QUERY);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error : {error.message}</p>;

    return (
        <TableContainer component={Paper}>
            <Table aria-label="data table">
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Created</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data[queryType].edges.map((entry) => (
                        <TableRow 
                            key={entry.node.id} 
                            component={Link} 
                            to={`/workflows/${entry.node.id}`}
                        >
                            <TableCell component="th" scope="row">
                                {entry.node.id}
                            </TableCell>
                            <TableCell>{entry.node.name}</TableCell>
                            <TableCell>{new Date(entry.node.created).toLocaleString()}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default DataTable;
