import React, { useState, useCallback, useEffect } from 'react';
import { Grid, Box } from '@mui/material';
import styled from 'styled-components';
import notify_icon from '../assets/notify-icon.svg';
import useWebSocket, { ReadyState } from 'react-use-websocket';

const Flyout = styled.div`
    background: #0F054A;
    border-radius: 15px 0px 0px 0px;
    padding:28px;
    color: #FFFFFF;

    @media screen and (min-width: 600px) {
        position:fixed;
        right:calc( -50vw - 96px);
        top:70px;
        height:calc( 90vh - 70px );
        width:50vw;
        max-width:540px;
        transition: right 0.4s ease-out;
        z-index:200;

        &.active{
            right:0;
        }
    }

`
const CloseButton = styled.button`
    position: absolute;
    background: #0F054A;
    border-radius: 20px 0px 0px 20px;
    height: 30px;
    left: -25px;
    top: 36px;
    border: none;
    color: #FFFFFF;
    font-size: 18px;
    padding-left: 8px;
    cursor: pointer;
`;

const events = [
    {
        "name": "Ojrn Thsgo",
        "description": "Lorem ipsum dolor sit amet, accusamus gbant pri ei...",
        "time": "2 hours ago",
        "change": "5.9%",
        "changeType": "decrease",
        "icon": "person"
    },
    {
        "name": "Tngsao",
        "description": "Lorem ipsum dolor sit amet, accusamus abhorreant pri ei, dolore,...",
        "time": "2 hours ago",
        "change": "5.9%",
        "changeType": "decrease",
        "icon": "network"
    },
    {
        "name": "Ojrn Thsgo",
        "description": "Lorem 181 events dolor sit amet, accusamus kolore...",
        "time": "2 hours ago",
        "change": "5.9%",
        "changeType": "decrease",
        "icon": "exclaim"
    },
    {
        "name": "Tngsao",
        "description": "Lorem ipsum dolor sit amet, accusamus abhorreant pri ei, dolore,...",
        "time": "2 hours ago",
        "change": "13%",
        "changeType": "middle",
        "icon": "bolt"
    },
    {
        "name": "Tngsao",
        "description": "Lorem ipsum dolor sit amet, accusamus abhorreant pri ei, dolore,...",
        "time": "2 hours ago",
        "change": "2.4%",
        "changeType": "decrease",
        "icon": "bolt"
    },
    {
        "name": "Tngsao",
        "description": "Lorem ipsum dolor sit amet, accusamus abhorreant pri ei, dolore,...",
        "time": "2 hours ago",
        "change": "78%",
        "changeType": "increase",
        "icon": "bolt"
    },
    {
        "name": "Tngsao",
        "description": "Lorem ipsum dolor sit amet, accusamus abhorreant pri ei, dolore,...",
        "time": "2 hours ago",
        "change": "50%",
        "changeType": "increase",
        "icon": "person"
    },
    {
        "name": "Tngsao",
        "description": "Lorem ipsum dolor sit amet, accusamus abhorreant pri ei, dolore,...",
        "time": "2 hours ago",
        "change": "5.9%",
        "changeType": "decrease",
        "icon": "bolt"
    },
    {
        "name": "Tngsao",
        "description": "Lorem ipsum dolor sit amet, accusamus abhorreant pri ei, dolore,...",
        "time": "2 hours ago",
        "change": "5.9%",
        "changeType": "decrease",
        "icon": "person"
    },
    {
        "name": "Tngsao",
        "description": "Lorem ipsum dolor sit amet, accusamus abhorreant pri ei, dolore,...",
        "time": "2 hours ago",
        "change": "5.9%",
        "changeType": "decrease",
        "icon": "person"
    },
    {
        "name": "Tngsao",
        "description": "Lorem ipsum dolor sit amet, accusamus abhorreant pri ei, dolore,...",
        "time": "2 hours ago",
        "change": "5.9%",
        "changeType": "decrease",
        "icon": "person"
    },
    {
        "name": "Tngsao",
        "description": "Lorem ipsum dolor sit amet, accusamus abhorreant pri ei, dolore,...",
        "time": "2 hours ago",
        "change": "5.9%",
        "changeType": "decrease",
        "icon": "person"
    },
    {
        "name": "Tngsao",
        "description": "Lorem ipsum dolor sit amet, accusamus abhorreant pri ei, dolore,...",
        "time": "2 hours ago",
        "change": "5.9%",
        "changeType": "decrease",
        "icon": "person"
    }
];

export default function EventFlyout({ showFlyout, handleShowFlyout }) {

    //Public API that will echo messages sent to it back to the client
    const [socketUrl, setSocketUrl] = useState('wss://dev.rundis.io/ws/events');
    const [messageHistory, setMessageHistory] = useState([]);

    const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl);

    useEffect(() => {
        if (lastMessage !== null) {
            setMessageHistory((prev) => [lastMessage, ...prev]);
        }
    }, [lastMessage, setMessageHistory]);

    const handleClickChangeSocketUrl = useCallback(
        () => setSocketUrl('wss://dev.rundis.io/ws/events'),
        []
    );

    //function to return time since a provided time
    function timeSince(time) {
        const old = new Date(time);
        const current = new Date();
        const seconds = Math.floor((current - old) / 1000);
        let interval = seconds / 31536000;
        if (interval > 1) {
            return Math.floor(interval) + " years ago";
        }
        interval = seconds / 2592000;
        if (interval > 1) {
            return Math.floor(interval) + " months ago";
        }
        interval = seconds / 604800;
        if (interval > 1) {
            return Math.floor(interval) + " weeks ago";
        }
        interval = seconds / 86400;
        if (interval > 1) {
            return Math.floor(interval) + " days ago";
        }
        interval = seconds / 3600;
        if (interval > 1) {
            return Math.floor(interval) + " hours ago";
        }
        interval = seconds / 60;
        if (interval > 1) {
            return Math.floor(interval) + " minutes ago";
        }
        return Math.floor(seconds) + " seconds ago";
    }

    function boldenName(description, name) {
        return (
            <>
                {description.split(name).map((part, index, array) =>
                    index === array.length - 1 ? part : <>
                        {part}
                        <strong>{name}</strong>
                    </>
                )}
            </>
        );
    }
    


    const handleClickSendMessage = useCallback(() => sendMessage('Hello'), []);

    const connectionStatus = {
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Open',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    }[readyState];

    if(messageHistory.length > 0){
        console.log(messageHistory);
    }
    

    return (
        <>
            <button className="eventsButton" onClick={handleShowFlyout}><img className="notify-icon" alt="svg-pack" src={notify_icon}/></button>
            <Flyout className={showFlyout ? "active" : null}>
                <div className="flyout__header">
                    <h3>Events</h3>
                    <h6>Total of 0 events today</h6>
                </div>
                <div className="flyout__feed">
                    {messageHistory && messageHistory.map((event, index) => {
                        let eventItem = JSON.parse(event.data);
                        return (
                            <div className="flyout__feed__event" key={'event-' + index}>
                                <div className={"flyout__feed__event__info icon-" + eventItem.kind}>
                                    <p>
                                        {boldenName(eventItem.short_description, eventItem.model.name)}
                                    </p>
                                </div>
                                <div className="flyout__feed__event__time">
                                    <h4>{timeSince(JSON.parse(event.data).created)}</h4>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <CloseButton onClick={handleShowFlyout}>X</CloseButton>
            </Flyout>
        </>
    )
}