import React, { useState, useEffect } from 'react'
import ThirtyFourDays from '../assets/34Days.png'
import PurpleCard from '../assets/purple_card.png'
import MonthlyUsage from '../assets/monthly_usage.png'
import { useQuery, gql } from '@apollo/client';

export default function RundisMetricsCard(props) {
    const {value, renderEightyFivePercentage, renderEightySixPercentage,  renderDays, renderMonthly, renderMonthlyRecurring } = props;

    
    function RenderEightyFivePercentage(value){
      return(
        <div className="card-wrapper">
            <div className="card-image bg--purple">
                <p className='card-percentage-text1'>{value} Total</p>
            </div>
        </div>
      )
    }

    function RenderEightySixPercentage(value){
        return(
          <div className="card-wrapper">
              <div className="card-image">
                  <p className='card-percentage-text2'>{value} Done</p>
              </div>
          </div>
        )
      }
    
    function RenderDays(value){
        return(
            <div className="card-wrapper">
                <div className="card-image">
                    <p className='card-percentage-text3'>{value} Day</p>
                </div>
            </div>
        )
      }
    
      function RenderMonthlyPercentage(value){
        return(
            <div className="card-wrapper">
                <div className="card-image bg--blue">
                    <p className='card-percentage-text-usage'>Monthly Usage</p>
                    <p className='card-percentage-text4'>+{value}%</p>
                </div>
            </div>
        )
      }

      function RenderMonthlyRecurring(value){
        return(
            <div className="card-wrapper">
                <div className="card-image bg--light-blue">
                    <p className='card-percentage-text-usage'>Monthly Recurring Charges</p>
                    <p className='card-percentage-text4'>${value}</p>
                </div>
            </div>
        )
      }

    return (
        <>
            {renderDays ? RenderDays(value) : ''}
            {renderMonthly ? RenderMonthlyPercentage(value) : ''}
            {renderEightyFivePercentage ? RenderEightyFivePercentage(value) : ''}
            {renderEightySixPercentage ? RenderEightySixPercentage(value) : ''}
            {renderMonthlyRecurring ? RenderMonthlyRecurring(value) : ''}
        </>
    )
}

