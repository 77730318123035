import React, { useState } from 'react';
import { Button, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';

export default function DateRangeDropdown() {
  const [value, setValue] = useState('lastDay');
  const [open, setOpen] = useState(false);

  const handleChange = (event) => {
    setValue(event.target.value);
    setOpen(false); // Close the dropdown when an option is selected
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  const getLabel = () => {
    switch(value) {
      case 'lastDay':
        return 'Last Day';
      case 'last7Days':
        return 'Last 7 Days';
      case 'last30Days':
        return 'Last 30 Days';
      case 'lastQuarter':
        return 'Last Quarter';
      case 'lastYear':
        return 'Last Year';
      default:
        return '';
    }
  };

  return (
    <FormControl component="fieldset" className='dateDropDown'>
      <Button onClick={handleToggle} className='dateButton'>{getLabel()}</Button>
      {open && (
        <RadioGroup aria-label="dateRange" name="dateRange" value={value} onChange={handleChange} className='dateRadios'>
          <FormControlLabel value="lastDay" control={<Radio />} label="Last Day" />
          <FormControlLabel value="last7Days" control={<Radio />} label="Last 7 Days" />
          <FormControlLabel value="last30Days" control={<Radio />} label="Last 30 Days" />
          <FormControlLabel value="lastQuarter" control={<Radio />} label="Last Quarter" />
          <FormControlLabel value="lastYear" control={<Radio />} label="Last Year" />
        </RadioGroup>
      )}
    </FormControl>
  );
}
