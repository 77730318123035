import React from 'react';
import HeaderNav from '../../components/HeaderNav';
import { Route, Routes } from 'react-router-dom';

export default function Header({ handleOpenAddModal, checkboxes, handleCheckboxChange, usage, handleUsageChange, showFlyout, handleShowFlyout, handleShowPlusMenu, plusMenu, showMenu, handleShowMenu, filterMenu, handleFilterMenu, showPlusMenu, isModalVisible, setModalVisible }) {
  return (
    <div className="header">
      <div className="flex-row flex-bottom block-mobile">
        <div className="header-title">
          <Routes>
            <Route path="/" element={<h1>Dashboard</h1>}/>
            <Route index path="/dashboard" element={<h1>Dashboard</h1>} />
            <Route path="/people" element={<h1>People</h1>} />
            <Route path="/things" element={<h1>Things</h1>} />
            <Route path="/providers" element={<h1>Providers</h1>} />
            <Route path="/workflow" element={<h1>Workflows</h1>} />
            <Route path="/actions" element={<h1>Actions</h1>} />
            <Route path="/secrets" element={<h1>Secrets</h1>} />
            <Route path="/warehouse" element={<h1>Warehouse</h1>} />
            <Route path="/resources" element={<h1>Resources</h1>} />
            <Route path="/settings" element={<h1>Settings</h1>} />
            <Route path="/account" element={<h1>Account</h1>} />
          </Routes>
        </div>
        <div className="header-text">
          <p>Welcome Back Michael</p>
        </div>
      </div>
      <HeaderNav handleOpenAddModal={handleOpenAddModal} filterMenu={filterMenu} handleFilterMenu={handleFilterMenu} showMenu={showMenu} handleShowMenu={handleShowMenu} checkboxes={checkboxes} handleCheckboxChange={handleCheckboxChange} usage={usage} handleUsageChange={handleUsageChange} showFlyout={showFlyout} handleShowFlyout={handleShowFlyout} plusMenu={plusMenu} handleShowPlusMenu={handleShowPlusMenu}  showPlusMenu={showPlusMenu} isModalVisible={isModalVisible} setModalVisible={setModalVisible} />
    </div>
  );
};
