import React from 'react';

const AddTriggerButton = ({ onToggle }) => {
    return (
        <button onClick={onToggle} className='createTrigger'>
            Create Trigger
        </button>
    );
};

export default AddTriggerButton;