import React, { useState } from 'react';
import { Routes, Route, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Grid, Box } from '@mui/material';
import '../../App.css';

import Footer from './Footer';
import Header from './Header';
import Sidebar from './Sidebar';
import Dashboard from '../../components/Dashboard';
import FlowCanvas from '../../components/FlowCanvas';
import WorkflowLinks from '../../components/WorkflowLinks';
import SlideInForm from '../../components/SlideInForm';
import WorkflowStepForm from '../../components/WorkflowStepForm';
import AddTriggerForm from '../../components/AddTriggerForm';
import AddModal from '../../components/AddModal';

const FlowHolder = styled.div`
  width: 100%;
  height: 64vh;
`;

const items = [
  { name: 'dashboard', label: 'Dashboard' },
  { name: 'people', label: 'People' },
  { name: 'things', label: 'Items/Things' },
  { name: 'providers', label: 'Providers' },
  { name: 'workflow', label: 'Workflow' },
  { name: 'actions', label: 'Actions' },
  { name: 'secrets', label: 'Secrets' },
  { name: 'resources', label: 'Resources' },
];

const WorkflowComponent = ({ openForm, setWorkflowSlug }) => {
  const { workflowSlug } = useParams();

  React.useEffect(() => {
    setWorkflowSlug(workflowSlug);
  }, [workflowSlug, setWorkflowSlug]);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={2}>
          <div className='leftWorkflow bgPurple roundedCorners'>
            <h3>
              <span>All Workflows</span>
              <button>+</button>
            </h3>
            <WorkflowLinks />
          </div>
        </Grid>
        <Grid item xs={12} md={10}>
          <div className='rightWorkflow bgPurple roundedCorners'>
            <FlowHolder>
              <FlowCanvas openForm={openForm} />
            </FlowHolder>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default function LayoutContainer() {
  const [checkboxes, setCheckboxes] = useState([
    { label: 'Has triggers', checked: false, query: 'hasTriggers' },
    { label: 'Has events', checked: false, query: 'hasEvents' },
    { label: 'Has results', checked: false, query: 'hasResults' },
    { label: 'Has steps', checked: false, query: 'hasSteps' },
  ]);

  const [usage, setUsage] = useState([0, 50]);
  const [workflowSlug, setWorkflowSlug] = useState(null);

  const handleUsageChange = (event, newUsage) => {
    setUsage(newUsage);
  };

  const [showFlyout, setShowFlyout] = useState(false);
  const [plusMenu, showPlusMenu] = useState(false);
  const [isModalVisible, setModalVisible] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [filterMenu, setFilterMenu] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [formDetails, setFormDetails] = useState({ isVisible: false, formType: null });

  const handleShowFlyout = (e) => {
    e.stopPropagation();
    showPlusMenu(false);
    setShowMenu(false);
    setFilterMenu(false);
    setShowFlyout(!showFlyout);
  };

  const handleShowPlusMenu = (e) => {
    e.stopPropagation();
    setShowFlyout(false);
    setShowMenu(false);
    setFilterMenu(false);
    if (!isModalVisible) {
      showPlusMenu(!plusMenu);
    }
    setModalVisible(false);
  };

  const handleCheckboxChange = (index, newCheckedState) => {
    const newCheckboxes = [...checkboxes];
    newCheckboxes[index].checked = newCheckedState;
    setCheckboxes(newCheckboxes);
  };

  const handleShowMenu = (e) => {
    e.stopPropagation();
    setShowFlyout(false);
    showPlusMenu(false);
    setFilterMenu(false);
    setShowMenu(!showMenu);
  };

  const handleFilterMenu = (e) => {
    e.stopPropagation();
    setShowFlyout(false);
    showPlusMenu(false);
    setShowMenu(false);
    setFilterMenu(!filterMenu);
  };

  const handleOpenAddModal = () => {
    showPlusMenu(false);
    setOpenAddModal(true);
  };

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
  };

  const openForm = (formType) => {
    setFormDetails({ isVisible: true, formType: formType });
  };

  const closeForm = () => {
    setFormDetails({ isVisible: false, formType: null });
  };

  const handleStepAdded = (step) => {
    console.log('Step added:', step);
    closeForm();
  };

  const renderForm = () => {
    const { formType } = formDetails;
    switch (formType) {
      case 'workflow':
        return <WorkflowStepForm onStepAdded={handleStepAdded} workflowSlug={workflowSlug} />;
      case 'trigger':
        return <AddTriggerForm onStepAdded={handleStepAdded} workflowSlug={workflowSlug} />;
      default:
        return null;
    }
  };

  return (
    <div className="gridwrap">
      <div className="sidebox">
        <Sidebar items={items} />
      </div>
      <div className="fullbox">
        <div className="topbox">
          <Header
            handleOpenAddModal={handleOpenAddModal}
            filterMenu={filterMenu}
            handleFilterMenu={handleFilterMenu}
            showMenu={showMenu}
            handleShowMenu={handleShowMenu}
            checkboxes={checkboxes}
            handleCheckboxChange={handleCheckboxChange}
            usage={usage}
            handleUsageChange={handleUsageChange}
            showFlyout={showFlyout}
            handleShowFlyout={handleShowFlyout}
            plusMenu={plusMenu}
            showPlusMenu={showPlusMenu}
            handleShowPlusMenu={handleShowPlusMenu}
            isModalVisible={isModalVisible}
            setModalVisible={setModalVisible}
          />
        </div>
        <div className="bottombox">
          <Routes>
            <Route
              path="/workflows/:workflowSlug"
              element={<WorkflowComponent openForm={openForm} setWorkflowSlug={setWorkflowSlug} />}
            />
            <Route
              path="*"
              element={
                <div className="main-content-container">
                  <Dashboard checkboxes={checkboxes} usage={usage} />
                </div>
              }
            />
          </Routes>
          <Footer />
        </div>
        <SlideInForm isVisible={formDetails.isVisible} onClose={closeForm}>
          {renderForm()}
        </SlideInForm>
      </div>
      <AddModal handleCloseAddModal={handleCloseAddModal} openAddModal={openAddModal} />
    </div>
  );
}