import React, { useState } from 'react';
import { useMutation, useQuery, gql } from '@apollo/client';

// Query to fetch triggers
const GET_TRIGGERS_QUERY = gql`
  query GetTriggers {
    triggers(first: 100) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

// Mutation to add a new workflow trigger
const ADD_WORKFLOW_TRIGGER_MUTATION = gql`
  mutation addWorkflowTrigger($id: ID!, $input: CreateWorkflowTriggerInput!) {
    addWorkflowTrigger(id: $id, input: $input) {
      id
      triggers {
        positionX
        positionY
        description
        trigger {
          name
        }
      }
    }
  }
`;

function AddTriggerForm({ onStepAdded, workflowSlug }) {
  const [formData, setFormData] = useState({
    triggerName: '',
    description: '',
    positionX: 0,
    positionY: 0,
    triggerID: '',
  });

  const { data: triggersData, loading: triggersLoading, error: triggersError } = useQuery(GET_TRIGGERS_QUERY);
  const [addWorkflowTrigger, { data, loading, error }] = useMutation(ADD_WORKFLOW_TRIGGER_MUTATION);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const triggerName = String(formData.triggerName).trim();
    const description = String(formData.description).trim();
    const triggerID = String(formData.triggerID).trim();

    console.log('workflow', workflowSlug);
    console.log('Trigger Name:', formData);

    if (!triggerName || !description || !triggerID) {
      alert("Trigger Name, Description, and Trigger ID are required");
      return;
    }

    try {
      const { data, loading, error } = await addWorkflowTrigger({
        variables: {
          id: workflowSlug,
          input: {
            name: triggerName,
            description: description,
            positionX: 0,
            positionY: 0,
            triggerID: triggerID,
          },
        },
      });
      onStepAdded(data.addWorkflowTrigger);
      setFormData({
        triggerName: '',
        description: '',
        positionX: 0,
        positionY: 0,
        triggerID: '',
      });
    } catch (err) {
      console.error("Submission error details:", err);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="slide-form">
      <h2>Add Trigger</h2>
      <div className="input-group">
        <label>Name:</label>
        <input
          type="text"
          name="triggerName"
          value={formData.triggerName}
          onChange={handleInputChange}
          required
        />
      </div>
      <div className="input-group">
        <label htmlFor="description">Description</label>
        <input
          type="text"
          name="description"
          value={formData.description}
          onChange={handleInputChange}
          placeholder="Description"
          required
        />
      </div>
      <div className="input-group">
        <label>Trigger Option:</label>
        <select
          className="select-style"
          name="triggerID"
          value={formData.triggerID}
          onChange={handleInputChange}
        >
          {triggersData && triggersData.triggers.edges.map(({ node }) => (
            <option key={node.id} value={node.id}>{node.name}</option>
          ))}
        </select>
      </div>
      <button type="submit">Submit</button>
      {error && <p>Error: {error.message}</p>}
      {triggersError && <p>Error fetching triggers: {triggersError.message}</p>}
      {data && <p>Step added successfully!</p>}
    </form>
  );
}

export default AddTriggerForm;