import React from 'react';
import { MarkerType, Position } from 'reactflow';

export const nodes = [
  {
    id: '1',
    data: {
      label: 'Trigger: Workday - Employee Terminated',
    },
    style: {
      background: '#2B6CB0',
      color: 'white',
    },
    position: { x: 0, y: 0 },
  },
  {
    id: '2',
    data: {
      label: 'Trigger: Workday - Employee On Leave',
    },
    style: {
      background: '#2B6CB0',
      color: 'white',
    },
    position: { x: 300, y: 0 },
  },
  {
    id: '3',
    data: {
      label: 'Disable Slack User',
    },
    position: { x: 150, y: 100 },
  },
  {
    id: '4',
    data: {
      label: 'Disable Office365 User',
    },
    position: { x: 150, y: 200 },
  },
  {
    id: '5',
    data: {
      label: 'Forward Email',
    },
    position: { x: 150, y: 300 },
  },
  {
    id: '6',
    data: {
      label: 'Notify Manager',
    },
    position: { x: 150, y: 405 },
  },
];

export const edges = [
  { id: 'e1-3', source: '1', target: '3' },
  { id: 'e2-3', source: '2', target: '3' },
  {
    id: 'e3-4',
    source: '3',
    target: '4'
  },
  {
    id: 'e4-5',
    source: '4',
    target: '5'
  },
  {
    id: 'e5-6',
    source: '5',
    target: '6'
  },
];