import React from 'react';
import { MarkerType, Position } from 'reactflow';

export const nodes = [
  {
    id: '1',
    data: {
      label: 'Trigger: GIT - New Tag Created',
    },
    style: {
      background: '#2B6CB0',
      color: 'white',
    },
    position: { x: 150, y: 0 },
  },
  {
    id: '2',
    data: {
      label: 'Build Application',
    },
    position: { x: 0, y: 100 },
  },
  {
    id: '3',
    data: {
      label: 'Update Documentation',
    },
    position: { x: 300, y: 100 },
  },
  {
    id: '4',
    data: {
      label: 'Deploy Application',
    },
    position: { x: 150, y: 200 },
  },
  {
    id: '5',
    data: {
      label: 'Run Post-Deployment Tests',
    },
    position: { x: 150, y: 300 },
  },
  {
    id: '6',
    data: {
      label: 'Notify Stakeholders',
    },
    position: { x: 150, y: 400 },
  },
];

export const edges = [
  { id: 'e1-2', source: '1', target: '2' },
  { id: 'e1-3', source: '1', target: '3' },
  {
    id: 'e2-4',
    source: '2',
    target: '4'
  },
  {
    id: 'e3-4',
    source: '3',
    target: '4'
  },
  {
    id: 'e4-5',
    source: '4',
    target: '5'
  },
  {
    id: 'e3-6',
    source: '3',
    target: '6'
  },
  {
    id: 'e4-6',
    source: '4',
    target: '6'
  },
];