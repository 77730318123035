import React, { useState } from 'react';
import styled from 'styled-components';
import { TextField, Button, Select, MenuItem, FormControl, InputLabel, OutlinedInput } from '@mui/material';
import adp from '../assets/providers-adp.png';
import aws from '../assets/providers-aws.png';
import git from '../assets/providers-git.png';
import jira from '../assets/providers-jira.png';
import qb from '../assets/providers-qb.png';
import salesforce from '../assets/providers-salesforce.png';
import sidebarProvider from '../assets/sidebar-provider.png';
import sidebarFlash from '../assets/sidebar-flash.png';
import sidebarOrgChart from '../assets/sidebar-org-chart.png';
import sidebarUser from '../assets/sidebar-user.png';
import sidebarPlus from '../assets/sidebar-plus.png';
import sidebarAlarm from '../assets/sidebar-alarm.png';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useMutation, gql } from '@apollo/client';

const CREATE_PROVIDER_MUTATION = gql`
  mutation CreateProvider($name: String!) {
    createProvider(
      input: {name: $name, providerObjectType: things, providerType: incoming_webhook}
    ) {
      id
      name
      incomingWebhookURL
    }
  }
`;

const StyledArrowDropDownIcon = styled(ArrowDropDownIcon)`
  color: #fff !important;
`;

const DropdownShell = styled.div`
    position: absolute;
    z-index: 10;
    left:-540px;
    display:flex;
    top:42px;
    align-items:flex-start;
`;

const DropDownContent = styled.div`
    border-radius: 5px 5px 10px 10px;
    background: #0F054A; 
    padding: 24px;
    width: 485px;
    color:#FFF;
    position: relative;
`;

const QuickButtons = styled.div`
    border-radius: 5px 5px 10px 10px;
    background: #0F054A;
    width: 40px;
    margin-left:4px;

    button{
        display:block;
        background: none;
        border: none;
        cursor: pointer;
        margin:0 4px;
        padding:8px;
        border-top: 1px solid rgba(143, 66, 203, 0.30);
    }
    img{
        display:block;
        width:100%;
        height:auto;
    }
`;

const ProviderButton = styled.button`
    border-radius: 5px;
    background: #231A58;
    width: 54px;
    height: 49px; 
    border: none;
    margin: 5px;
    cursor: pointer;

    img {
        max-width: 32px; // Adjust size accordingly
        height: auto;
    }
`;

const TagHolder = styled.div`
    paddingLeft: '100px'
`;

const headings = {
    color: "#D38AFF",
    textAlign: "center",
    fontFamily: "Roboto",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    textAlign: "left"
};

const updatedHeadings = {
    ...headings,
    color: "#FFA3B9"
};



const Tag = styled.span`
    display: inline-block;
    margin: 5px;
    padding: 5px 10px;
    background-color: rgba(35, 26, 88, 0.5);
    border-radius: 5px;
    cursor: pointer;
    color: #E8E8E8;
    font-family: Inter;
    font-size:10px;

    &:after{
        content: '+';
        transform: rotate(45deg);
        margin-left: 10px;
        display: inline-block;
        font-size: 20px;
        line-height: 10px;
        vertical-align: -3px;
    }
`;

const FormRow = styled.div`
    display: flex;
    align-items: center;
    margin: 10px 0;
    label {
        width: 100px;
        font-size: 11px;
    }
`;

const HookContainer = styled.div`
    border-radius: 5px;
    background: #231A58;
    padding:9px 45px 9px 6px;
    color: rgba(232, 232, 232, 0.80);
    font-family: Inter;
    font-size: 13px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
`;

const WebSocketDisplay = styled.div`
    border-radius: 5px 5px 10px 10px;
    background: #231A58;
    min-height: 112px;
    padding:12px 10px;
    margin-bottom:28px;
    margin-top:24px;

    h4{
        margin: 0;
    }
`;

const NextStepsButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    button{
        border:none;
        background:none;
        color: rgba(255, 255, 255, 0.80);
        text-align: center;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        cursor: pointer;
    }
`;

const PlusResultsContainer = styled.div`

    h3{
        text-align: center;
        font-size:14px;
        margin-bottom:9px;
    }

    h5{
        color: #FFF;
        text-align: center;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top:0;
    }
    
    p{
        color: #FFF;
        font-family: Roboto;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-left:9px;
        margin-right:12px;

        span{
            color: #E2A5FF; 
        }

        a{
            color: #FFA3CF; 
            text-decoration: none;
        }
    }
`;

const textInputs = {
    width: "320px",
    height: "21px",
    borderRadius: "5px",
    background:" #231A58",
    color: "rgba(232, 232, 232, 0.80)",
    padding: 0
}

const textAreaInputs = {
    ...textInputs,
    height: "200px"
};

const submitButton = {
    color: "#FFF",
    fontFamily: "Roboto",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "800",
    lineHeight: "normal",
    float: "right"
}

const inputLabel = {
    color: "#FFF"
}

const tagHolderStyle = {
    paddingLeft: '100px'
}

const paddingNone = {
    padding: 0
}

const buttonBoxStyle = {
    display: 'flex'
}

const hidePanelStyle = {
    display: 'none'
}

const PlusDropdown = ({ isVisible, onClose }) => {
    const [formData, setFormData] = useState({
        appName: '',
        dataType: '',
        tagInput: '',
        tags: [],
        additionalNotes: ''
    });

    const [showWebhook, setShowWebhook] = useState(false);

    const [providerName, setProviderName] = useState("");
    const [providerNotes, setProviderNotes] = useState("");

    const [createProvider, { data }] = useMutation(CREATE_PROVIDER_MUTATION);

    const handleProviderSubmit = async (event) => {
        event.preventDefault();
        try {
        await createProvider({ variables: { name: providerName } });
            setProviderName("");
            setProviderNotes("");
            setShowWebhook(!showWebhook);
        } catch (error) {
            console.error("Error creating provider: ", error);
        }
    };
    

    const handleSetShowWebhook = () => {
        setShowWebhook(!showWebhook);
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };

    const handleAddTag = (event) => {
        if (event.key === 'Enter' && formData.tagInput) {
            setFormData(prevState => ({
                ...prevState,
                tags: [...prevState.tags, prevState.tagInput],
                tagInput: ''
            }));
            event.preventDefault(); // prevent the default form submission on enter
        }
    };

    const handleRemoveTag = (index) => {
        setFormData(prevState => ({
            ...prevState,
            tags: prevState.tags.filter((_, idx) => idx !== index)
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log('Submitted:', formData);
        onClose();
    };

    if (!isVisible) return null;

    return (
        <DropdownShell>
            <DropDownContent>
                {showWebhook ? (
                    <PlusResultsContainer>
                        <h2 style={headings} >Adding a New Provider</h2>
                        <h3>Your Webhook URL</h3>
                        <h5>You’ll need to configure your application with this webhook URL:</h5>
                        <HookContainer>
                            {data && (
                                <>
                                https://hooks.rundis.com{data.createProvider.incomingWebhookURL}
                                </>
                            )}
                        </HookContainer>
                        <p>
                            We’ve generated a custom webhook URL for you to send requests to. You can add <span>silent/</span> if your application prefers getting an empty response. <a href="#">Learn more about using webhooks</a>. 
                        </p>
                        <WebSocketDisplay>
                            <h4>We are listening!</h4>
                            <div>

                            </div>
                        </WebSocketDisplay>
                        <NextStepsButtonContainer>
                            <button onClick={handleSetShowWebhook}>Add another Webhook</button>
                            <button>Setup a Trigger</button>
                            <button>My Webhooks</button>
                        </NextStepsButtonContainer>
                    </PlusResultsContainer>
                ) : (
                    <div>
                        <h2 style={headings} >Adding a New Provider</h2>
                        <div style={buttonBoxStyle}>
                            <ProviderButton><img src={adp} alt="ADP" /></ProviderButton>
                            <ProviderButton><img src={aws} alt="AWS" /></ProviderButton>
                            <ProviderButton><img src={git} alt="Git" /></ProviderButton>
                            <ProviderButton><img src={jira} alt="Jira" /></ProviderButton>
                            <ProviderButton><img src={qb} alt="QuickBooks" /></ProviderButton>
                            <ProviderButton><img src={salesforce} alt="Salesforce" /></ProviderButton>
                        </div>

                        <h3 style={updatedHeadings} >Setup Incoming Webhook</h3>
                        <form>
                        <FormRow>
                                <InputLabel htmlFor="appName" style={inputLabel} >Application Name</InputLabel>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    name="appName"
                                    variant="outlined"
                                    value={providerName}
                                    onChange={e => setProviderName(e.target.value)}
                                    style={textInputs}
                                    inputProps={{
                                        style: {
                                            width: "320px",
                                            height: "21px",
                                            borderRadius: "5px",
                                            background:" #231A58",
                                            color: "rgba(232, 232, 232, 0.80)",
                                            fontSize: "11px",
                                            padding:"4px 8px"
                                        }
                                    }}
                                />
                            </FormRow>

                            <FormRow>
                                <InputLabel htmlFor="dataType" style={inputLabel}>Type of Data</InputLabel>
                                <FormControl variant="outlined" margin="dense">
                                    <Select
                                        value={formData.dataType}
                                        onChange={handleInputChange}
                                        name="dataType"
                                        input={<OutlinedInput label="Type of Data" />}
                                        IconComponent={StyledArrowDropDownIcon} 
                                        sx={{
                                            width: "320px",
                                            height: "30px",
                                            borderRadius: "5px",
                                            background:" #231A58",
                                            color: "rgba(232, 232, 232, 0.80)",
                                            fontSize: "11px",
                                            padding:"4px 8px"
                                        }}
                                    >
                                        <MenuItem value="People">People</MenuItem>
                                        <MenuItem value="Things">Things</MenuItem>
                                    </Select>
                                </FormControl>
                            </FormRow>

                            <FormRow>
                                <InputLabel htmlFor="tagInput" style={inputLabel}>Tags</InputLabel>
                                <TextField
                                    margin="dense"
                                    name="tagInput"
                                    variant="outlined"
                                    value={formData.tagInput}
                                    onChange={handleInputChange}
                                    onKeyPress={handleAddTag}
                                    inputProps={{
                                        style: {
                                            width: "304px",
                                            height: "21px",
                                            borderRadius: "5px",
                                            background:" #231A58",
                                            color: "rgba(232, 232, 232, 0.80)",
                                            fontSize: "11px",
                                            padding:"4px 8px"
                                        }
                                    }}
                                />
                            </FormRow>

                            <div style={tagHolderStyle}>
                                {formData.tags.map((tag, index) => (
                                    <Tag key={index} onClick={() => handleRemoveTag(index)}>
                                        {tag}
                                    </Tag>
                                ))}
                            </div>

                            <FormRow>
                                <InputLabel htmlFor="additionalNotes" style={inputLabel}>Additional Notes</InputLabel>
                                <TextField
                                    margin="dense"
                                    name="additionalNotes"
                                    variant="outlined"
                                    multiline
                                    rows={4}
                                    value={providerNotes}
                                    onChange={e => setProviderNotes(e.target.value)}
                                    sx={{
                                        '& .MuiInputBase-root': {
                                            padding: 0
                                        }
                                    }}
                                    inputProps={{
                                        style: {
                                            width: "304px",
                                            height: "42px",
                                            borderRadius: "5px",
                                            background:" #231A58",
                                            color: "rgba(232, 232, 232, 0.80)",
                                            padding: 0,
                                            fontSize: "11px",
                                            padding:"4px 8px"
                                        }
                                    }}
                                />
                            </FormRow>
                            <Button type="submit" color="primary" style={submitButton} onClick={handleProviderSubmit} >
                                Generate URL
                            </Button>
                        </form>    
                    </div>
                ) }
            </DropDownContent>
            <QuickButtons>
                <button>
                    <img src={sidebarProvider} alt="Add Provider" />
                </button>
                <button>
                    <img src={sidebarAlarm} alt="Add Alarm" />
                </button>
                <button>
                    <img src={sidebarFlash} alt="Add Trigger" />
                </button>
                <button>
                    <img src={sidebarOrgChart} alt="Add Workflow" />
                </button>
                <button>
                    <img src={sidebarUser} alt="Add Person" />
                </button>
                <button>
                    <img src={sidebarPlus} alt="Add Custom" />
                </button>
            </QuickButtons>
        </DropdownShell>
    );
};

export default PlusDropdown;
