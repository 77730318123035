import React, { useState } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';

// Define the ADD_WORKFLOW_STEP mutation within the same file
const ADD_WORKFLOW_STEP = gql`
  mutation AddWorkflowStep($id: ID!, $input: CreateStepInput!) {
    addWorkflowStep(id: $id, input: $input) {
      id
      name
      steps {
        name
        positionX
        positionY
        dependsOn {
          id
          name
        }
        dependents {
          id
          name
        }
        action {
          id
          name
        }
      }
      triggers {
        description
        positionX
        positionY
        trigger {
          id
          name
        }
      }
    }
  }
`;

// Define the GET_ACTIONS query to fetch actions
const GET_ACTIONS = gql`
  query GetActions {
    actions {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

const stepInput = {
    positionX: 150,
    positionY: 300,
    name: "One more update",
    actionID: "20"
  };

const WorkflowStepForm = ({ onStepAdded, workflowSlug }) => {
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    positionX: 0,
    positionY: 0,
    actionID: '',
  });

  const { data: actionsData, loading: actionsLoading, error: actionsError } = useQuery(GET_ACTIONS);
  const [addWorkflowStep, { data, loading, error }] = useMutation(ADD_WORKFLOW_STEP);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Ensure that stepName, description, and actionID are strings
    const stepName = String(formData.stepName).trim();
    const description = String(formData.description).trim();
    const actionID = String(formData.actionID).trim();

    // Ensure that positionX and positionY are integers
    const positionX = parseInt(formData.positionX, 10);
    const positionY = parseInt(formData.positionY, 10);

    console.log("Form data:", { stepName, description, positionX, positionY, actionID });

    if (!stepName || !description || !actionID) {
      alert("Step Name, Description, and Action ID are required");
      return;
    }

    try {
      const { data, loading, error } = await addWorkflowStep({
        variables: {
          id: workflowSlug,
          input: {
            positionX: positionX,
            positionY: positionY,
            name: stepName,
            actionID: actionID
          },
        },
      });
      onStepAdded(data.addWorkflowStep);
      setFormData({
        positionX: positionX,
        positionY: positionY,
        name: stepName,
        actionID: actionID
      });
    } catch (err) {
      console.error("Submission error details:", err);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="slide-form">
      <h2>Add Workflow Step</h2>
      <div className="input-group">
        <label htmlFor="stepName">Step Name</label>
        <input
          type="text"
          name="stepName"
          value={formData.stepName}
          onChange={handleInputChange}
          placeholder="Step Name"
          required
        />
      </div>
      <div className="input-group">
        <label htmlFor="description">Description</label>
        <input
          type="text"
          name="description"
          value={formData.description}
          onChange={handleInputChange}
          placeholder="Description"
          required
        />
      </div>
      <div className="input-group">
        <label htmlFor="positionX">Position X</label>
        <input
          type="number"
          name="positionX"
          value={formData.positionX}
          onChange={handleInputChange}
          placeholder="Position X"
          required
        />
      </div>
      <div className="input-group">
        <label htmlFor="positionY">Position Y</label>
        <input
          type="number"
          name="positionY"
          value={formData.positionY}
          onChange={handleInputChange}
          placeholder="Position Y"
          required
        />
      </div>
      <div className="input-group">
        <label htmlFor="actionID">Action</label>
        <select
          className="select-style"
          name="actionID"
          value={formData.actionID}
          onChange={handleInputChange}
          required
        >
          <option value="">Select Action</option>
          {actionsLoading && <option>Loading actions...</option>}
          {actionsError && <option>Error loading actions</option>}
          {actionsData && actionsData.actions.edges.map(({ node }) => (
            <option key={node.id} value={node.id}>{node.name}</option>
          ))}
        </select>
      </div>
      <button type="submit" disabled={loading}>Submit</button>
      {error && <p>Error: {error.message}</p>}
      {data && <p>Step added successfully!</p>}
    </form>
  );
};

export default WorkflowStepForm;