import React from 'react'
import { List, ListItem, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';
import dash_icon from '../../assets/dash-icon.svg'
import circle_user from '../../assets/circle-user.svg'
import org_chart from '../../assets/organization-chart.svg'
import flash from '../../assets/flash.svg'
import pack from '../../assets/package.svg'
import boxi from '../../assets/box.svg'
import arriw from '../../assets/arrow-transfer-2.svg'
import secrets from '../../assets/padlock-open.svg'
import logo from '../../assets/logo.png';
import logo_mini from '../../assets/logo-mini.svg';
import sidebar_open from '../../assets/sidebar-open.svg';
import sidebar_close from '../../assets/sidebar-close.svg';
import { useEffect, useState } from 'react';

const iconMappings = {
    'Dashboard': dash_icon,
    'People':  circle_user,
    'Items/Things': boxi,
    'Providers': arriw,
    'Workflow': org_chart,
    'Actions': flash,
    'Secrets': secrets,
    'Resources': pack
}

export default function Sidebar({ items }) {
    const [showSide, setShowSide] = useState(true);
    useEffect(() => {
        const handler = () => setShowSide(true);
    });
    const handleInputClick = (e) => {
        e.stopPropagation();
        setShowSide(!showSide);
    };

    return (
        <div className={showSide ? "sidebar" : "sidebar closed"}>
            <div className="sidebar-header">
                <img src={logo} className="logo" alt="rundis" />
                <img src={logo_mini} className="logo-mini" alt="rundis" />
            </div>
            <List disablePadding dense>
                {items.map(({ label, name, ...rest }) => (
                    <ListItem key={name} button {...rest}>
                        <div className="sidebar-icons"><img alt="svg-pack" src={iconMappings[label]}/></div>
                        <ListItemText>
                            <Link className="sidebar-list" to={`/${name}`}>{label}</Link>
                        </ListItemText>
                    </ListItem>
                ))}
                <div className="account-settings">
                    <ListItem>
                        <ListItemText>
                            <Link className="sidebar-link" to='/settings'>Settings</Link>
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemText>
                            <Link className="sidebar-link" to="/account">Account</Link>
                        </ListItemText>
                    </ListItem>
                </div>
            </List>
            <button className="btn-sidebar" onClick={handleInputClick}>
                <img src={sidebar_open} className="sidebar-open" alt="rundis" />
                <img src={sidebar_close} className="sidebar-close" alt="rundis" />
            </button>
            <div className="footer">
                <span className="footer-text">
                    <a id="footer-link" href="http://rundis.io">MJ Labs-Project Disrupt</a> <br></br> &copy; 2022 MJ Group Inc.
                </span>
            </div>
        </div>
    )
}