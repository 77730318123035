import React, { useState } from 'react';
import { useMutation, useQuery, gql } from '@apollo/client';

// Query to fetch triggers
const GET_TRIGGERS_QUERY = gql`
  query GetTriggers {
    triggers(first: 100) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

// Mutation to add a new workflow trigger
const ADD_WORKFLOW_TRIGGER_MUTATION = gql`
  mutation addWorkflowTrigger($id: ID!, $input: AddWorkflowTriggerInput!) {
    addWorkflowTrigger(id: $id, input: $input) {
      id
      triggers {
        positionX
        positionY
        description
        trigger {
          name
        }
      }
    }
  }
`;

function CreateTriggerComponent({ workflowId }) {
  const [showForm, setShowForm] = useState(false);
  const [description, setDescription] = useState('');
  const [triggerOption, setTriggerOption] = useState('');

  const { data: triggersData, loading: triggersLoading, error: triggersError } = useQuery(GET_TRIGGERS_QUERY);
  const [addWorkflowTrigger, { data: mutationData, loading: mutationLoading, error: mutationError }] = useMutation(ADD_WORKFLOW_TRIGGER_MUTATION);

  const handleCreateTrigger = () => {
    setShowForm(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const variables = { 
      id: workflowId, 
      input: { 
        triggerID: triggerOption, 
        description: description, 
        positionX: 150, 
        positionY: 0 
      } 
    };

    // Log the variables before executing the mutation
    console.log('Executing mutation with variables:', variables);

    addWorkflowTrigger({ variables });
    setShowForm(false);
  };

  console.log('Query Data:', triggersData);
  console.log('Query Loading:', triggersLoading);
  console.log('Query Error:', triggersError);
  console.log('Mutation Data:', mutationData);
  console.log('Mutation Loading:', mutationLoading);
  console.log('Mutation Error:', mutationError);

  if (triggersLoading) return <p>Loading triggers...</p>;
  if (triggersError) return <p>Error loading triggers: {triggersError.message}</p>;

  return (
    <div>
      <button onClick={handleCreateTrigger} className='createTrigger'>Create Trigger</button>
      {showForm && (
        <div>
          <form onSubmit={handleSubmit}>
            <label>
              Description:
              <input 
                type="text" 
                value={description} 
                onChange={(e) => setDescription(e.target.value)} 
              />
            </label>
            <label>
              Trigger Option:
              <select value={triggerOption} onChange={(e) => setTriggerOption(e.target.value)}>
                {triggersData && triggersData.triggers.edges.map(({ node }) => (
                  <option key={node.id} value={node.id}>{node.name}</option>
                ))}
              </select>
            </label>
            <button type="submit">Submit</button>
          </form>
        </div>
      )}
    </div>
  );
}

export default CreateTriggerComponent;
