import React, { useState, useEffect } from 'react';
import filter_icon from '../assets/filter-icon.svg';
import filter_icon_white from '../assets/filter-icon-white.svg';

import { 
        Checkbox, 
        Slider, 
        Switch, 
        FormGroup, 
        FormControlLabel, 
        Accordion, 
        AccordionSummary, 
        AccordionDetails, 
        Typography
} from '@mui/material'; 
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

    function valuetext(value) {
        return `${value}`;
    }

export default function Filters({ checkboxes, handleCheckboxChange, usage, handleUsageChange, filterMenu, handleFilterMenu }) {
    const [open, setOpen] = React.useState(false);

    const accordionStyle = {
        backgroundColor: "transparent",
        color: '#FFF',
        boxShadow: 'none',
    }
    
    const accordionHeadStyle = {
        fontFamily: 'Roboto',
        fontSize: '12px',
        color: '#EEACE3',
        paddingLeft: '0px',
    }

    const checkBoxStyle = {
        width: '11px',
        height: '11px',
        color: "#FFF",
        backgroundColor: "rgba(255, 255, 255, 0.56) ",
        marginRight: '12px',
        '&.Mui-checked': {
            color: "#FFF",
         },
    }

    const rangeStyles = {
        color: "rgba(253, 180, 255, 0.7)", //color of the slider between thumbs
        "& .MuiSlider-thumb": {
          backgroundColor: "#D9D9D9",
          border: "2px solid #FF3EEC" //color of thumbs
        },
        "& .MuiSlider-rail": {
          color: "rgba(217, 217, 217, 0.7)" ////color of the slider outside  teh area between thumbs
        }
      }
    
      const theme = createTheme({
        components: {
          MuiSwitch: {
            styleOverrides: {
              switchBase: {
                // Controls default (unchecked) color for the thumb
                color: "#ccc",
              },
              colorPrimary: {
                "&.Mui-checked": {
                  // Controls checked color for the thumb
                  color: "#fff"
                }
              },
              track: {
                // Controls default (unchecked) color for the track
                backgroundColor: "rgba(216, 121, 218, 1) ",
                border: "1px solid #fff",
                ".Mui-checked.Mui-checked + &": {
                  // Controls checked color for the track
                  backgroundColor: "rgba(216, 121, 218, 1)"
                }
              }
            }
          }
        }
      });

    return (
        <>
            
            <button className={filterMenu ? "active nav-filter" : "nav-filter"} style={{cursor: "pointer"}} onClick={handleFilterMenu}>
                <div className="nav-filter-text">
                    <img alt="svg-pack" src={filterMenu ? filter_icon_white : filter_icon}/>
                    &nbsp;&nbsp;&nbsp;Filters
                </div>
            </button>
            <div className="filterDropDown">
                <div className="filterDropDown__header">
                    <h3>Filters</h3>
                    <button>
                        Clear all
                    </button>
                    <div style={{color:'#FFF', padding: '0 8px'}}>
                        -
                    </div>
                    <button>
                        Save view
                    </button>
                </div>
                <Accordion style={accordionStyle}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={accordionHeadStyle}
                    >
                        <Typography>Usage</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Slider
                            getAriaLabel={() => 'Usage range'}
                            value={usage}
                            onChange={handleUsageChange}
                            valueLabelDisplay="auto"
                            getAriaValueText={valuetext}
                            sx={rangeStyles}
                        />
                    </AccordionDetails>
                </Accordion>
                <Accordion style={accordionStyle}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                        style={{...accordionHeadStyle, color: '#FFBBBB'}}
                    >
                    <Typography>Options</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <FormGroup>
                        {checkboxes.map((checkbox, index) => (
                            <FormControlLabel
                            key={index}
                            control={
                                <Checkbox
                                checked={checkbox.checked}
                                onChange={(event) => handleCheckboxChange(index, event.target.checked)}
                                inputProps={{ 'aria-label': checkbox.label }}
                                sx={checkBoxStyle}
                                />
                            }
                            label={checkbox.label}
                            />
                        ))}
                        </FormGroup>
                    </AccordionDetails>
                </Accordion>
                <Accordion style={accordionStyle}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3a-content"
                        id="panel3a-header"
                        style={{...accordionHeadStyle, color: '#FFD4B6'}}
                    >
                    <Typography>Status / Visibility</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ThemeProvider theme={theme}>
                            <FormGroup>
                                <FormControlLabel control={<Switch defaultChecked />} label="Active Status" />
                                <FormControlLabel required control={<Switch />} label="Turn on/off function" />
                            </FormGroup>
                        </ThemeProvider>
                    </AccordionDetails>
                </Accordion>
                <div className='filterDropDown__footer'>
                    <button className="filterSubmit">
                        Apply
                    </button>
                </div>
            </div>
        </>
    )
    
}