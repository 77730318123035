import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { Link, useLocation } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

const WorkflowLinks = (props) => {
    const queryType = props.queryType ? props.queryType : 'workflows';
    const location = useLocation();

    // The GraphQL query should be adjusted based on your schema and needs
    const WORKFLOWS_QUERY = gql`
        query GetWorkflows {    
            workflows {
                edges {
                    node {
                        id
                        name
                    }
                }
            }
        }
    `;

    const { loading, error, data } = useQuery(WORKFLOWS_QUERY);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    return (
        <List>
            {data.workflows.edges.map((workflow) => (
                <ListItem key={workflow.node.id} className={location.pathname === `/workflows/${workflow.node.id}` ? 'active' : ''}>
                    <Link to={`/workflows/${workflow.node.id}`}>{workflow.node.name}</Link>
                </ListItem>
            ))}
        </List>
    );
};

export default WorkflowLinks;
