import React, { useState } from 'react';
import { useMutation, gql } from '@apollo/client';

const ADD_WORKFLOW_STEP = gql`
  mutation AddWorkflowStep($id: ID!, $input: CreateStepInput!) {
    addWorkflowStep(id: $id, input: $input) {
      id
      name
      steps {
        name
        positionX
        positionY
        dependsOn {
          id
          name
        }
        dependents {
          id
          name
        }
        action {
          id
          name
        }
      }
      triggers {
        description
        positionX
        positionY
        trigger {
          id
          name
        }
      }
    }
  }
`;

const WorkflowStepManager = ({ stepId, input }) => {
  const [addWorkflowStep, { data, loading, error }] = useMutation(ADD_WORKFLOW_STEP);
  const [steps, setSteps] = useState([]);

  const handleAddWorkflowStep = async () => {
    try {
      console.log('Variables:', { id: stepId, input });  // Debugging log
      const result = await addWorkflowStep({ variables: { id: stepId, input } });
      setSteps(result.data.addWorkflowStep.steps);
    } catch (e) {
      console.error('Mutation error', e);
    }
  };

  return (
    <div>
      <button onClick={handleAddWorkflowStep} disabled={loading}>
        {loading ? 'Adding...' : 'Add Workflow Step'}
      </button>
      {error && (
        <div>
          <p>Error: {error.message}</p>
          <pre>{JSON.stringify(error, null, 2)}</pre>
        </div>
      )}
      {data && steps.length > 0 && (
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Position X</th>
              <th>Position Y</th>
              <th>Depends On</th>
              <th>Dependents</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {steps.map((step) => (
              <tr key={step.name}>
                <td>{step.name}</td>
                <td>{step.positionX}</td>
                <td>{step.positionY}</td>
                <td>
                  {step.dependsOn.map((dep) => (
                    <div key={dep.id}>{dep.name}</div>
                  ))}
                </td>
                <td>
                  {step.dependents.map((dep) => (
                    <div key={dep.id}>{dep.name}</div>
                  ))}
                </td>
                <td>{step.action.name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default WorkflowStepManager;