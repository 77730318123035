import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactFlow, {
  addEdge,
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
} from 'reactflow';
import { useMutation, useQuery, gql } from '@apollo/client';
import CreateTriggerComponent from './CreateTriggerComponent';
import AddWorkflowStepButton from './AddWorkflowStepButton';
import AddTriggerButton from './AddTriggerButton';

//import { nodes as initialNodes, edges as initialEdges } from './git-release.js';
import CustomNode from './CustomNode';

import 'reactflow/dist/style.css';
import './overview.css';

function convertGraphQLDataToFormat(inputData) {
  const edges = inputData.workflows.edges;

  const workflow = edges.length > 0 ? edges[0].node : null;
  const formattedData = [];

  // Add an image node as the first item
  formattedData.push({
      id: 'image-node', // Unique ID for the image node
      type: 'image', // Custom node type for image
      className: 'baseNodeSpinner',
      data: {
          // You can add additional data if required
          image: 'path_to_your_image.jpg', // Path to your image
      },
      position: {
          // Set the position for the image node
          x: 50, // Example x position
          y: 0, // Example y position
      },
  });

  // Adding the original steps from the input data, if available
  if (workflow && workflow.steps) {
      workflow.steps.forEach((step) => {
          formattedData.push({
              id: step.id,
              data: {
                  label: step.name,
              },
              position: {
                  x: step.positionX,
                  y: step.positionY,
              },
          });
      });
  }

  // Calculate y position for the "See Suggested Steps" node
  const yPosForSuggestedSteps = 50 * (formattedData.length + 1);

  // Add a "See Suggested Steps" node at the end
  formattedData.push({
      id: 'suggested-steps-node',
      className: 'suggestedSteps', // Custom class for this node
      data: {
          label: 'See Suggested Steps',
      },
      position: {
          x: 50, // Example x position, adjust as needed
          y: yPosForSuggestedSteps, // Example y position, adjust as needed
      },
  });


  return formattedData;
}

function createDependencyLinks(inputData) {
    const steps = inputData.workflows.edges[0].node.steps;
    let dependencyLinks = [];
  
    steps.forEach(step => {
      step.dependents.forEach(dependent => {
        dependencyLinks.push({
          id: `${step.id}-${dependent.id}`,
          source: step.id,
          target: dependent.id
        });
      });
    });

    console.log('dependencyLinks', dependencyLinks);
  
    return dependencyLinks;
}

const nodeTypes = {
  custom: CustomNode,
};

const minimapStyle = {
  height: 120,
};

const onInit = (reactFlowInstance) => console.log('flow loaded:', reactFlowInstance);
 
export default function FlowCanvas({ openForm }) {

  const { workflowSlug } = useParams();

  const GET_WORKFLOW = gql`
    query queryWorkflow {
        workflows(where: { id: "${workflowSlug}" }){
            edges{
                node{
                id
                name
                description
                steps{
                    id
                    name
                    positionX
                    positionY
                    dependsOn{
                    id
                    }
                    dependents{
                    id
                    }
                    action{
                    id
                    name
                    }
                }
                triggers{
                    id
                    name
                    description
                    positionX
                    positionY
                    trigger{
                    id
                    name
                    }
                }
                }
            }
        }
    }
`;
    const UPDATE_NODE_POSITION = gql`
    mutation updateStep($workFlowId: ID!, $nodeId: ID!, $positionX: Int!, $positionY: Int!) {
        updateWorkflowStep(
            id: $workFlowId,
            stepId: $nodeId,
            input: {
                positionX: $positionX
                positionY: $positionY
            }
        ){
            id
            name
            steps{
                id
                name
                positionX
                positionY
                dependsOn{
                    id
                }
                dependents{
                    id
                }
            }
        }
    }
    `;

    // States for processed data
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [timeoutId, setTimeoutId] = useState(null);
    const [updateNodePosition] = useMutation(UPDATE_NODE_POSITION);
    const [triggers, setTriggers] = useState([]);

    // Apollo Client useQuery hook with GraphQL query
    const { loading, error, data } = useQuery(GET_WORKFLOW);

    console.log('data', data);

    //const myFlowId = data.workflows.edges[0].node.id;
    const myFlowId = '68719476739'; // Replace with actual workflow ID

    // Effect hook to process data when it's fetched
    React.useEffect(() => {
        if (data) {
        // Process the data with the provided functions
        const formattedNodes = convertGraphQLDataToFormat(data);
        const dependencyEdges = createDependencyLinks(data);

        const workflow = data.workflows.edges[0]?.node;
        if (workflow && workflow.triggers) {
          setTriggers(workflow.triggers);
        }

        // Update the nodes and edges state
        setNodes(formattedNodes);
        setEdges(dependencyEdges);
        }
    }, [data]);

    const handleNodeChange = (changes) => {
        console.log("handleNodeChange called with changes:", changes);
      
        setNodes((currentNodes) => 
          currentNodes.map((node) => {
            const change = changes.find((ch) => ch.id === node.id && ch.type === 'position');
      
            if (change && change.position) {
              console.log("Node change identified for node:", node.id, "New position:", change.position);
      
              // Clear the existing timeout
              if (timeoutId) clearTimeout(timeoutId);
      
              // Set a new timeout
              const newTimeoutId = setTimeout(() => {
                console.log("Executing mutation for node:", node.id);
                updateNodePosition({
                    variables: {
                        workFlowId: myFlowId, // Replace with actual workflow ID
                        nodeId: node.id,
                        positionX: Math.round(change.position.x),
                        positionY: Math.round(change.position.y)
                    }
                  }).then(response => {
                    console.log("Mutation response:", response);
                  }).catch(error => {
                    console.error("Mutation error:", error);
                    if (error.networkError && error.networkError.result) {
                      console.error("Detailed error message:", error.networkError.result.errors);
                    }
                  });
      
              }, 500); // Delay
      
              setTimeoutId(newTimeoutId);
              return { ...node, position: change.position };
            }
            return node;
          })
        );
      };
      

    const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), []);

    // we are using a bit of a shortcut here to adjust the edge type
    // this could also be done with a custom edge for example
    const edgesWithUpdatedTypes = edges.map((edge) => {
        if (edge.sourceHandle) {
        const edgeType = nodes.find((node) => node.type === 'custom').data.selects[edge.sourceHandle];
        edge.type = edgeType;
        }

        return edge;
    });

  return (
    <>
      <div class="displayFlex positionAbsolute">
        <AddTriggerButton onToggle={() => openForm('trigger')} />
        <AddWorkflowStepButton onToggle={() => openForm('workflow')} />
        <button className="organizeNodes"></button>
      </div>
      <div class="triggerContainer">
        <button class="setupTrigger">Setup a New Trigger</button>
        {triggers.map((trigger) => (
          <button className="setupTrigger sub" key={trigger.id}>
            <div className="logo"></div>
            <div className="text">
              <span>Workday</span>
              {trigger.name}
            </div>
            <div>
              <button className="edit"></button>
            </div>
          </button>
        ))}
      </div>
      
      <ReactFlow
        nodes={nodes}
        edges={edgesWithUpdatedTypes}
        onNodesChange={handleNodeChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onInit={onInit}
        fitView
        attributionPosition="top-right"
        nodeTypes={nodeTypes}
      >
        <Controls position="top-right" />
      </ReactFlow>
    </>
  );

}