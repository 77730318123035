import React, { useState } from 'react'
import { Grid, Box } from '@mui/material';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import RundisMetricsCard from './RundisMetricsCard';
import ApolloMetricCard from './ApolloMetricCard';
import OpenRevenue from '../assets/OpenRevenue.png'
import styled from 'styled-components';
import { WebSocketDemo } from './WebSocketDemo';
import DateRangeDropdown from './DateRangeDropdown';
import video from '../assets/ai-animation.mp4';
import DataTable from './DataTable';
import ReactFlowContainer from './ReactFlowContainer';
import DisableEmployeeContainer from './DisableEmployeeContainer';
import AwsAlertContainer from './AwsAlertContainer';
import GitReleaseContainer from './GitReleaseContainer';
import WorkflowLinks from './WorkflowLinks';
import WorkflowStepManager from './WorkflowStepManager';
import AiChatBox from './AiChatBox';

const StyledDashboard = styled.div`
   
    @media screen and (min-width: 600px) {

    }

`

const StyledBoxWrapper = styled.div`
    padding:30px;
   
    @media screen and (min-width: 600px) {
        width: 100%;
        padding: 80px 50px 50px;
        box-sizing: border-box;
    }
`

const DashboardCardsOnLeft = styled.div`
    
    @media screen and (min-width: 600px) {
        display: flex;
        flex-direction: row;
    }
`
const DashboardCardsOnRight = styled.div`

    @media screen and (min-width: 600px) {
        display: flex;
        flex-direction: row;
        padding-left: 20px;
    }
`

const AiAssistantContainer = styled.div`
    cursor:pointer;

    @media screen and (min-width: 600px) {
        display:block;
        width:75px;
        height:75px;
        position: absolute;
        bottom: 18px;
        right: 24px;
        border-radius: 50%;
        overflow: hidden;
    }
`

const AiAssistantLabel = styled.div`
  color:#541884;
  font-family: 'Roboto', sans-serif;
  font-size:16px;
  cursor:pointer;

  @media screen and (min-width: 600px) {
    font-size:21px;
    position: absolute;
    bottom:42px;
    right: 108px;
  }
`

const FlowHolder = styled.div`
  width:80vw;
  height:64vh;
`

//add values for all cards based on route
const valuesObject = {
    "dashboard": {
        "total" : '72',
        "monthly" : '12',
        "recurring": '200,000',
    },
    "people": {
        "total" : 99,
        "monthly" : 25,
        "recurring": '300,000',
    },
    "things": {
        "total" : 40,
        "monthly" : 72,
        "recurring": '400,000',
    },
    "providers": {
        "total" : 64,
        "monthly" : 72,
        "recurring": '500,000',
    },
    "workflow": {
        "total" : 18,
        "monthly" : 3,
        "recurring": '600,000',
    },
    "actions": {
        "total" : 24,
        "monthly" : 8,
        "recurring": '700,000',
    },
    "secrets": {
        "total" : 3,
        "monthly" : 1,
        "recurring": '800,000',
    },
    "warehouse": {
        "total" : 32,
        "monthly" : 9,
        "recurring": '900,000',
    },
    "resources": {
        "total" : 18,
        "monthly" : 6,
        "recurring": '1,000,000',
    },
    "settings": {
        "total" : 0,
        "monthly" : 0,
        "recurring": 0,
    },
    "account": {
        "total" : 1,
        "monthly" : 1,
        "recurring": 0,
    }
}

const stepInput = {
    positionX: 0,
    positionY: 200,
    name: "Another Test Step",
    actionID: "10"
  };

export default function Dashboard({checkboxes, usage}) {

    const navigate = useNavigate();

    const navigateToAiAssistant = () => {
        navigate('/aiassistant');
    };

    return (
        <StyledDashboard>
            <StyledBoxWrapper>
                <Box>
                    <Grid container spacing={2} >
                        <Routes>
                            <Route path="/flowexample" element={<FlowHolder><ReactFlowContainer /></FlowHolder>}/>
                            <Route path="/flowexample-employee" element={<FlowHolder><DisableEmployeeContainer /></FlowHolder>}/>
                            <Route path="/flowexample-aws" element={<FlowHolder><AwsAlertContainer /></FlowHolder>}/>
                            <Route path="/flowexample-git" element={<FlowHolder><GitReleaseContainer /></FlowHolder>}/>
                            <Route path="/aiassistant" element={
                                <>
                                    <Grid item xs={12} md={6}>
                                        <AiChatBox />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <RundisMetricsCard value={valuesObject.people.total} renderEightyFivePercentage />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <RundisMetricsCard value={valuesObject.warehouse.recurring} renderMonthlyRecurring />
                                    </Grid>
                                </>
                            }/>
                        </Routes>
                        <Grid item xs={12} md={3}>
                            <Routes>
                                <Route path="/" element={<RundisMetricsCard value={valuesObject.dashboard.total} renderEightyFivePercentage />}/>
                                <Route index path="/dashboard" element={<RundisMetricsCard value={valuesObject.dashboard.total} renderEightyFivePercentage />} />
                                <Route path="/people" element={<RundisMetricsCard value={valuesObject.people.total} renderEightyFivePercentage />} />
                                <Route path="/things" element={<RundisMetricsCard value={valuesObject.things.total} renderEightyFivePercentage />} />
                                <Route path="/providers" element={<ApolloMetricCard checkboxes={checkboxes} usage={usage} bgColor='bg--purple' suffix='Total' textStyle='card-percentage-text1' />} />
                                <Route path="/workflow" element={<ApolloMetricCard checkboxes={checkboxes} usage={usage} queryType='workflows' bgColor='bg--purple' suffix='Total' textStyle='card-percentage-text1' />} />
                                <Route path="/actions" element={<ApolloMetricCard checkboxes={checkboxes} usage={usage} queryType='actions' bgColor='bg--purple' suffix='Total' textStyle='card-percentage-text1' />} />
                                <Route path="/secrets" element={<RundisMetricsCard value={valuesObject.secrets.total} renderEightyFivePercentage />} />
                                <Route path="/warehouse" element={<RundisMetricsCard value={valuesObject.warehouse.total} renderEightyFivePercentage />} />
                                <Route path="/resources" element={<RundisMetricsCard value={valuesObject.resources.total} renderEightyFivePercentage />} />
                                <Route path="/settings" element={<RundisMetricsCard value={valuesObject.settings.total} renderEightyFivePercentage />} />
                                <Route path="/account" element={<RundisMetricsCard value={valuesObject.account.total} renderEightyFivePercentage />} />
                            </Routes>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Routes>
                                <Route path="/" element={<RundisMetricsCard value={valuesObject.dashboard.monthly} renderMonthly />}/>
                                <Route index path="/dashboard" element={<RundisMetricsCard value={valuesObject.dashboard.monthly} renderMonthly />} />
                                <Route path="/people" element={<RundisMetricsCard value={valuesObject.people.monthly} renderMonthly />} />
                                <Route path="/things" element={<RundisMetricsCard value={valuesObject.things.monthly} renderMonthly />} />
                                <Route path="/providers" element={<RundisMetricsCard value={valuesObject.providers.monthly} renderMonthly />} />
                                <Route path="/workflow" element={<RundisMetricsCard value={valuesObject.workflow.monthly} renderMonthly />} />
                                <Route path="/actions" element={<RundisMetricsCard value={valuesObject.actions.monthly} renderMonthly />} />
                                <Route path="/secrets" element={<RundisMetricsCard value={valuesObject.secrets.monthly} renderMonthly />} />
                                <Route path="/warehouse" element={<RundisMetricsCard value={valuesObject.warehouse.monthly} renderMonthly />} />
                                <Route path="/resources" element={<RundisMetricsCard value={valuesObject.resources.monthly} renderMonthly />} />
                                <Route path="/settings" element={<RundisMetricsCard value={valuesObject.settings.monthly} renderMonthly />} />
                                <Route path="/account" element={<RundisMetricsCard value={valuesObject.account.monthly} renderMonthly />} />
                            </Routes>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Routes>
                                <Route path="/" element={<RundisMetricsCard value={valuesObject.dashboard.recurring} renderMonthlyRecurring />}/>
                                <Route index path="/dashboard" element={<RundisMetricsCard value={valuesObject.dashboard.recurring} renderMonthlyRecurring />} />
                                <Route path="/people" element={<RundisMetricsCard value={valuesObject.people.recurring} renderMonthlyRecurring />} />
                                <Route path="/things" element={<RundisMetricsCard value={valuesObject.things.recurring} renderMonthlyRecurring />} />
                                <Route path="/providers" element={<RundisMetricsCard value={valuesObject.providers.recurring} renderMonthlyRecurring />} />
                                <Route path="/workflow" element={<RundisMetricsCard value={valuesObject.workflow.recurring} renderMonthlyRecurring />} />
                                <Route path="/actions" element={<RundisMetricsCard value={valuesObject.actions.recurring} renderMonthlyRecurring />} />
                                <Route path="/secrets" element={<RundisMetricsCard value={valuesObject.secrets.recurring} renderMonthlyRecurring />} />
                                <Route path="/warehouse" element={<RundisMetricsCard value={valuesObject.warehouse.recurring} renderMonthlyRecurring />} />
                                <Route path="/resources" element={<RundisMetricsCard value={valuesObject.resources.recurring} renderMonthlyRecurring />} />
                                <Route path="/settings" element={<RundisMetricsCard value={valuesObject.settings.recurring} renderMonthlyRecurring />} />
                                <Route path="/account" element={<RundisMetricsCard value={valuesObject.account.recurring} renderMonthlyRecurring />} />
                            </Routes>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <Routes>
                                <Route path="/providers" element={<DataTable checkboxes={checkboxes} usage={usage} className='resultsTable' />} />
                                <Route path="/workflow" element={<DataTable checkboxes={checkboxes} usage={usage} queryType='workflows' className='resultsTable' />} />
                                <Route path="/actions" element={<DataTable checkboxes={checkboxes} usage={usage} queryType='actions' className='resultsTable' />} />
                            </Routes>
                        </Grid>
                    </Grid>
                    <DateRangeDropdown />
                </Box>
            </StyledBoxWrapper>
            <AiAssistantContainer className="AiVideoHolder" onClick={navigateToAiAssistant}>
                <video autoPlay={true} muted={true} loop={true} id="aiVideo">
                    <source src={video} type="video/mp4" />
                </video>
            </AiAssistantContainer>
            <AiAssistantLabel onClick={navigateToAiAssistant}>Ask Your Personal Assistant</AiAssistantLabel>
        </StyledDashboard>
    )
}