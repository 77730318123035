import { useQuery, gql } from '@apollo/client';

function ApolloMetricCard(props) {

const queryType = props.queryType ? props.queryType : 'providers';
const queryFunction = props.queryType ? props.queryType : 'queryProvidersWithEventsInRange';
const bgColor = props.bgColor ? props.bgColor + ' card-image' : 'card-image';
const suffix = props.suffix ? props.suffix : '';
const textStyle = props.textStyle ? props.textStyle : 'card-percentage-text4';
const prefix = props.prefix ? props.prefix : '';
const hasEvents = props.checkboxes[1].checked ? 'hasEvents: true' : '';
const hasTriggers = (props.checkboxes[0].checked  && queryType == 'workflows' ) ? 'hasTriggers: true' : '';
const hasResults = (props.checkboxes[2].checked  && queryType != 'providers') ? 'hasResults: true' : '';
const hasSteps = (props.checkboxes[3].checked && queryType == 'workflows' ) ? 'hasSteps: true' : '';
const hasEventsInRange = (queryType == 'providers' && (props.usage[0] != 0 || props.usage[1] != 50)) ? 'hasEventsWithinRange: [' + props.usage[0] + ', ' + props.usage[1] + ']' : '';

const GET_QUERY = gql`
    query ${queryType}{
        ${queryType}(where:{
            ${hasEvents}
            ${hasTriggers}
            ${hasResults}
            ${hasSteps}
            ${hasEventsInRange}
        }){
        edges{
            node{
                id
                name
                created
            }
        }
        totalCount
        }
   }
`;

    const { loading, error, data } = useQuery(GET_QUERY);
  
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error : {error.message}</p>;
  
    return (
        <div className="card-wrapper">
            <div className={bgColor}>
                {
                    props.cardHeading && (
                        <p className='card-percentage-text-usage'>{props.cardHeading}</p>
                    )
                }
                <p className={textStyle}>{prefix}{data[queryType].totalCount} {suffix}</p>
            </div>
        </div>
    );
  }

  export default ApolloMetricCard;